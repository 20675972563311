
<template>
  <div class="fulfillment">
    <fieldset class="location">
      <label>
        <input
          type="radio"
          name="location"
          :value="location"
          @change="change('location', 'takeaway')"
          v-bind:checked="location == 'takeaway'"
          />
        Take-Away
      </label>
      <label>
        <input
          type="radio"
          name="location"
          :value="location"
          @change="change('location', 'premises')"
          v-bind:checked="location == 'premises'"
          />
        Dine-In
      </label>
    </fieldset>
    <Transition name="slide-fade">
      <fieldset v-if="location == 'takeaway' " class="container">
        <label>
          <input
            type="radio"
            name="container"
            :value="container"
            @change="change('container', 'oneway')"
            v-bind:checked="container == 'oneway'"
            />
          Einweggeschirr
        </label>
        <label>
          <input
            type="radio"
            name="container"
            :value="container"
            @change="change('container', 'vytal')"
            v-bind:checked="container == 'vytal'"
            />
          Vytal-Mehrweggeschirr
        </label>
      </fieldset>
    </transition>
  </div>
</template>

<script>

import ky from 'ky'
import store from '@/store'

export default {
  name: 'Fulfillment',
  methods: {
    change(key, value) {
      var json = {}
      json[key] = value
      ky.post(process.env.VUE_APP_API_URL + '/order/fulfillment', {
        json: json,
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('fulfillment', data)
      })
    }
  },
  computed: {
    base () {
      return process.env.VUE_APP_API_URL
    },
    location() {
      return store.state.fulfillment.location
    },
    container() {
      return store.state.fulfillment.container
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

.fulfillment fieldset {
  border: none;
}
.fulfillment label {
  cursor: pointer;
  user-select: none;
}


</style>
