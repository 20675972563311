
<template>
  <div class="payment">
    <fieldset class="method">
      <label class="card" v-if="paymethods.includes('stripe')">
        <input
          type="radio"
          name="method"
          :value="method"
          @change="change('card')"
          v-bind:checked="method == 'card'"
          />
        Kreditkarte oder SEPA-Lastschrift 
      </label>
      <label class="paypal" v-if="paymethods.includes('paypal')">
        <input
          type="radio"
          name="method"
          :value="method"
          @change="change('paypal')"
          v-bind:checked="method == 'paypal'"
          />  <!-- disabled=disabled -->
          PayPal <!-- <small>(PayPal vorübergehend ausser Betrieb)</small> -->
      </label>
    </fieldset>
    <Transition name="slide-fade">
      <div class="logos">
        <span class="card" v-if="method=='card'">
          <img src="../assets/p/visa.svg" />
          <img src="../assets/p/mastercard.svg" />
          <img src="../assets/p/elv.svg" />
          <img src="../assets/p/google-pay.svg" />
          <img src="../assets/p/apple-pay.svg" />
        </span>
        <span class="paypal" v-if="method=='paypal'">
          <img src="../assets/p/paypal.svg" />
        </span>
      </div>
      </Transition>
  </div>
</template>

<script>

import ky from 'ky'
import store from '@/store'

export default {
  name: 'Payment',
  methods: {
    change(method) {
      ky.post(process.env.VUE_APP_API_URL + '/order/method', {
        json: method,
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(method => {
        store.commit('method', method)
      })
    }
  },
  computed: {
    method() {
      return store.state.method
    },
    paymethods() {
      return store.state.venue[this.$route.params.venue].paymethods
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

.payment {
  fieldset {
    border: none;
  }
  label {
    cursor: pointer;
    user-select: none;
  }
  .logos {
    display: flex;
    justify-content: center;
    img {
      width: 50px;
      margin: 0px 6px;
    }
    .paypal {
      img {
      width: 80px;
      }
    }
    margin: 0 auto;
  }
}

</style>

