<template>
  <div class="summary framer">
    <router-link class="back" :to="{name:'Home',params:{venue:this.$route.params.venue}}"><img src="../assets/back.svg" /></router-link>
    <Venue class="venue" />
    <img class="logo" src="../assets/logo.svg">
    <h1>Ihre Bestellung</h1>
    <Fulfillment class="fulfillment" />
    <Payment v-if="has_payment_select" class="payment" />
    <Order class="order-summary" />
    <br v-if="hasDiscount">
    <Discount />
    <input v-if=" ! hasDiscount" class="discount-code" type="text" @input="tryDiscount($event.target.value)" placeholder="Gutscheincode" />
    <a v-if="over_minimum" href="" class="button call-to-action pay-button-card" @click.prevent="submit">
      Bestellen 
    </a>
    <span v-else>Bitte gehen Sie einen Schritt zurück und wählen Sie Gerichte und Getränke, um zu bestellen</span>
  </div>
</template>

<script>
import ky from 'ky'
import Order from '@/components/Order.vue'
import Fulfillment from '@/components/Fulfillment.vue'
import Payment from '@/components/Payment.vue'
import Discount from '@/components/Discount.vue'
import Venue from '@/components/Venue.vue'


export default {
  name: 'Summary',
  data() {
    return {
      discountEntry: false
    }
  },
  components: {
    Order, Fulfillment, Payment, Discount, Venue
  },
  methods: {
    submit () {
      switch (this.$store.state.method) {
        case 'card':
          ky.post(process.env.VUE_APP_API_URL + '/stripe/submit/' + this.$route.params.venue, {
            headers: {
              'session-token': localStorage.getItem('session token')
            }
          }).json().then(data => {
            window.location.href = data
          })
        break
        case 'paypal':
          ky.post(process.env.VUE_APP_API_URL + '/paypal/submit/' + this.$route.params.venue, {
            headers: {
              'session-token': localStorage.getItem('session token')
            }
          }).json().then(data => {
            window.location.href = data
          }).catch(() => {
            alert("PayPal ist vorübergehend nicht erreichbar. Bitte versuchen Sie es später erneut, oder verwenden Sie ein anderes Zahlungsmittel.")
          })
        break
        default:
          throw "Unknown payment method " + this.$store.state.method
      }
    },
    tryDiscount(potentialCode) {
      ky.post(process.env.VUE_APP_API_URL + '/order/discount/' + potentialCode, {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        this.$store.commit('discount', data)
        potentialCode= ''
      }).catch(() => {
      })
    }
  },
  computed: {
    over_minimum() {
      return Object.entries(this.$store.state.order).length > 0
    },
    has_payment_select() {
      if (process.env.VUE_APP_STRIPE_TERMINAL_ENABLE == '1') {
        // this block will be completely removed on build
        return false
      }
      return true
    },
    hasDiscount() {
      if (this.$store.state.discount)
        return true
      return false
    }
  }
}

</script>

<style scoped lang="scss">
.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<style lang="scss">

table.order-summary {
  td {
    text-align: right;
  }
  th {
    text-align: left;
  }
  td:first {
    text-align: left;
  }
  margin-bottom: 2em;
}

input.discount-code {
  margin-top: 1em;
  &:not(:placeholder-shown) {
    text-transform: uppercase;
    font-weight: bold;
  }
  min-width:200px;
}

</style>
