
<template>
  <h2 v-if="rendered_order.total">
    <template v-if="rendered_order.total_discounted">
      <s>{{ as_eur(rendered_order.total) }}</s>
      {{ as_eur(rendered_order.total_discounted) }}
    </template>
    <template v-else>
      {{ as_eur(rendered_order.total) }}
    </template>
  </h2>
  <table>
    <template v-for="dish in rendered_order.dishes" :key="dish.key">
      <tr>
        <th>{{ dish.name }}</th>
        <td>
          <template v-if="dish.discount">
            <s>{{ as_eur(dish.price ) }}</s>
            {{ as_eur(dish.discounted ) }}
          </template>
          <template v-else>
            {{ as_eur(dish.price ) }}
          </template>
        </td>
      </tr>
      <tr class="modifier" v-for="component in dish.components" :key="component.key">
        <th>{{ component.name }}</th>
        <td>
          <template v-if="component.discount">
            <s>{{ as_eur(component.price ) }}</s>
            {{ as_eur(component.discounted) }}
          </template>
          <template v-else>
            {{ as_eur(component.price ) }}
          </template>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>

import store from '@/store'

import { order_iter } from '@/order'

export default {
  name: 'Order',
  props: {
    msg: String
  },
  methods: {
  },
  computed: {
    base () {
      return process.env.VUE_APP_API_URL
    },
    rendered_order () {
      if ( ! store.state.dishes) {
        return {
          dishes:[],
          total: null,
          total_discounted: null
        }
      }

      var dishes = []
      var total = 0
      var total_discounted = 0

      for (var [dish, modifier_iter] of order_iter(store.state)) {
        var components = []
        for (var [modifier, component_iter] of modifier_iter) {
          modifier  // explicitly unused
          for (var component of component_iter) {
            components.push(component)
            total += component.price
            total_discounted += component.discounted
          }
        }

        dish.components = components
        dishes.push(dish)
        total += dish.price
        total_discounted += dish.discounted
      }

      return {
        dishes: dishes,
        total: total,
        total_discounted: total_discounted
      }
    }
  },
  components: {
  }
}
</script>

<style lang="scss">

tr {
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
  &.modifier {
    th {
      padding-left: 18px;
    }
  }
}
s {
  color: red;
}
h2 {
  s {
    font-size: 75%;
  }
}

</style>
